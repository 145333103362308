import { HeaderTab } from 'hive-mui-utils';
import { OidcContextProps } from 'hive-oidc-react';

export enum Paths {
  Root = '/',
  Rooms = '/rooms',
  Call = '/call',
  About = '/about',

  SigningIn = '/oidc',
  SignedIn = '/signed-in',
  SignedOut = '/signed-out',
}

export const TAB_ROOMS: HeaderTab = {
  path: Paths.Rooms,
  labelId: 'tabs.rooms',
};

export const TAB_ABOUT: HeaderTab = {
  path: Paths.About,
  labelId: 'tabs.about',
};

export const STORAGE_KEY_OIDC_PROVIDER_ID = 'oidc-provider';

const ERROR_MESSAGES: string[] = [
  'signin.error.message1',
  'signin.error.message1',
];

export const OIDC_CONTEXT_PROPS: OidcContextProps = {
  loggedInPath: Paths.SigningIn,
  loggedOutPath: Paths.SignedOut,
  errorPath: Paths.Root,
  storageKey: STORAGE_KEY_OIDC_PROVIDER_ID,
  errorMessages: ERROR_MESSAGES,
};
