import { PropsWithChildren } from 'react';
import { CircularProgress, Stack } from '@mui/material';

export function Loading({ children }: PropsWithChildren<any>) {
  return (
    <Stack
      flexGrow={1}
      alignContent="center"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <CircularProgress />

      {children}
    </Stack>
  );
}
