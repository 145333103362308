import { createTheme, ThemeOptions } from '@mui/material';
import { muiThemeOptions } from 'hive-mui-utils';

import backgroundUrl from '../assets/oidc-background.jpg';
import backgroundUrlDark from '../assets/oidc-background-dark.jpg';

const themeOptions: ThemeOptions = {
  components: {
    MuiChip: {
      defaultProps: {
        color: 'primary',
      },
    },

    MuiIcon: {
      defaultProps: {
        color: 'primary',
      },
    },

    MuiStack: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.oidc.providers, &.main-content-container': {
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: `url('${backgroundUrl}')`,
            ...theme.applyStyles('dark', {
              backgroundImage: `url('${backgroundUrlDark}')`,
            }),
          },
        }),
      },
    },

    MuiCircularProgress: {
      defaultProps: {
        color: 'primary',
      },
    },

    MuiSvgIcon: {
      defaultProps: {
        color: 'primary',
      },
    },
  },
};

export const appTheme = createTheme(
  {
    colorSchemes: {
      light: true,
      dark: true,
    },
  },
  muiThemeOptions,
  themeOptions
);
