export const en = {
  'app.title': 'ACS App',

  common: {
    ok: 'OK',
    cancel: 'Cancel',
    create: 'Create',
    edit: 'Edit',
    delete: 'Delete',
    save: 'Save',
    yes: 'Yes',
    no: 'No',
    error: 'An error as occurred',
  },

  name: {
    unknown: 'Unknown',
  },

  signin: {
    oidc: {
      title: 'Welcome to Azure Communication Service Demo',
    },
  },

  guest: {
    title: 'To sign in as a guest, please provide your email address.',
    button: 'Continue as guest',
    email: {
      placeholder: 'Enter your email address',
      invalid: 'Invalid email address',
    },
  },

  header: {
    title: 'ACS App',
  },

  tabs: {
    rooms: 'Rooms',
    about: 'About',
  },

  about: {
    acs: {
      userId: 'ACS User ID',
      token: 'ACS Token',
      token_valid: 'ACS Token Valid',
    },

    languages: {
      supported: 'Supported Languages',
      current: 'Current Language',
      en: 'English',
      fr: 'Français',
    },

    dark_mode: {
      supported: 'Supports Dark Mode',
      light: 'Light',
      dark: 'Dark',
      system: '{{systemMode}} (System)',
    },

    guests: {
      supported: 'Supports Guest Users',
      current: 'User Type',
      external: 'User',
      bee: 'Guest',
    },

    oidc: {
      provider: 'OpenID Connect Provider',
      entraid: 'Microsoft Entra ID',
      keycloak: 'Keycloak',
    },

    theme: 'Theme',

    version: 'Application Version',
  },

  rooms: {
    title: 'Rooms',
    not_assigned: 'You are not assigned to any room',

    actions: {
      create: {
        button: 'Create Room...',

        title: {
          new: 'Create New Room',
          edit: 'Edit Room {{name}}',
        },

        properties: {
          name: 'Room Name',
        },
      },

      delete: {
        title: 'Delete Room',
        message: 'Delete Room {{id}}',
      },
    },

    participants: {
      button: 'Modify participants...',
      dialog: {
        title: 'Modify participants',
      },
    },
  },

  room: {
    id: 'ID: {{id}}',
    participants: 'Participants',
    no_assigned: 'No participants are assigned',

    remote: {
      title: 'Remote Participants',
      empty: 'There are no connected participants',
    },

    unconnected: {
      title: 'Unconnected Participants',
      empty: 'There are no unconnected participants',
    },

    waiting: {
      title: 'Waiting Participants',
      empty: 'There are no waiting participants',
      admit: 'Admit',
      deny: 'Deny',
    },

    eject: {
      title: 'Eject Participant',
      description: 'Eject {{name}} from this call?',
    },

    accept: {
      title: 'Accept Admission',
      description: 'Accept {{name}} admission to this call?',
    },

    deny: {
      title: 'Deny Admission',
      description: 'Deny {{name}} admission to this call?',
    },
  },

  call: {
    title: 'Room {{name}}',
    join: 'Join a call',
    details: 'Room {{name}}',
  },

  users: {
    title: 'Users',
    no_unassigned: 'No Unassigned Users',
  },
};
