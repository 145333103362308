import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CallComposite as BaseCallComposite } from '@azure/communication-react';
import { Stack as BaseFluentStack, initializeIcons } from '@fluentui/react';
import { useTitleEffect } from 'hive-react-utils';

import { Paths } from '../../constants';
import { Loading } from '../Loading';
import {
  PartialCompositeLocale,
  useAcsCallAdapter,
  useAcsCallLocale,
  useAcsCallOptions,
  useAcsCallState,
  useAcsFormFactor,
  useAcsTheme,
  useUsers,
} from '../../hooks';
import { Room } from '../../types';
import { CallDrawer } from './CallDrawer';
import { AcsCallContextProvider } from '../../contexts';

initializeIcons(undefined, { disableWarnings: true });

const Container = styled(Stack)({
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
});

const FluentStack = styled(BaseFluentStack)({
  width: '100%',
  flexGrow: 1,
});

const CallComposite = styled(BaseCallComposite)({
  width: '100%',
});

export interface MeetingProps {
  room: Room;
}

export function Meeting({ room }: MeetingProps): JSX.Element {
  const { t } = useTranslation();
  const users = useUsers();

  const adapter = useAcsCallAdapter(room);
  const options = useAcsCallOptions();
  const fluentTheme = useAcsTheme();
  const formFactor = useAcsFormFactor();
  const callState = useAcsCallState(adapter);

  const localeOverrides = useMemo<PartialCompositeLocale>(
    () => ({
      strings: {
        call: {
          configurationPageTitle: t('call.join'),
          configurationPageCallDetails: room
            ? t('call.details', { name: room.properties.name })
            : undefined,
        },
      },
    }),
    [room, t]
  );

  const locale = useAcsCallLocale(localeOverrides);

  useTitleEffect(
    room ? t('call.title', { name: room.properties.name }) : t('app.title')
  );

  const navigate = useNavigate();
  const onBackToRooms = useCallback(() => navigate(Paths.Rooms), [navigate]);

  return (
    <Container>
      <AcsCallContextProvider
        adapter={adapter}
        room={room}
        users={users}
        callState={callState}
      >
        <CallDrawer />

        {!adapter && (
          <Loading>
            <Button variant="contained" onClick={onBackToRooms}>
              {t('common.cancel')}
            </Button>
          </Loading>
        )}

        {adapter && (
          <FluentStack>
            <CallComposite
              fluentTheme={fluentTheme}
              adapter={adapter}
              formFactor={formFactor}
              options={options}
              locale={locale}
            />
          </FluentStack>
        )}
      </AcsCallContextProvider>
    </Container>
  );
}
