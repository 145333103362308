import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AzureCommunicationCallAdapterArgs,
  AzureCommunicationCallAdapterOptions,
  AzureCommunicationOutboundCallAdapterArgs,
  CallAdapter,
  onResolveVideoEffectDependencyLazy,
  useAzureCommunicationCallAdapter,
} from '@azure/communication-react';
import { useConnection } from 'hive-react-utils';

import { AppContext } from '../contexts';
import { Room } from '../types';
import { useAcsCredentials } from './useAcsCredentials';
import { useAcsRoomLocator } from './useAcsRoomLocator';
import { useAcsVideoBackgroundImages } from './useAcsVideoBackgroundImages';

export function useAcsCallAdapter(room?: Room): CallAdapter | undefined {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  const connection = useConnection();
  const credential = useAcsCredentials();
  const locator = useAcsRoomLocator(room);
  const videoBackgroundImages = useAcsVideoBackgroundImages();

  const adapterOptions = useMemo<AzureCommunicationCallAdapterOptions>(
    () => ({
      videoBackgroundOptions: {
        videoBackgroundImages: videoBackgroundImages,
        onResolveDependency: onResolveVideoEffectDependencyLazy,
      },
    }),
    [videoBackgroundImages]
  );

  const adapterArgs = useMemo<
    Partial<
      | AzureCommunicationCallAdapterArgs
      | AzureCommunicationOutboundCallAdapterArgs
    >
  >(
    () => ({
      userId: { communicationUserId: user?.acsUserId || '' },
      displayName: user?.name || connection?.username || t('name.unknown'),
      credential,
      locator,
      options: adapterOptions,
    }),
    [
      adapterOptions,
      connection?.username,
      credential,
      locator,
      t,
      user?.acsUserId,
      user?.name,
    ]
  );

  return useAzureCommunicationCallAdapter(adapterArgs);
}
