import { Breakpoint, Theme, useMediaQuery } from '@mui/material';
import { useMemo } from 'react';

export type AcsFormFactor = 'desktop' | 'mobile';

export function useAcsFormFactor(
  downBreakpoint: Breakpoint | number | undefined = 'md'
): AcsFormFactor {
  const isDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(downBreakpoint)
  );
  return useMemo<AcsFormFactor>(() => {
    return isDown ? 'mobile' : 'desktop';
  }, [isDown]);
}
