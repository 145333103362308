import { useEffect, useState } from 'react';
import { CallAdapter, CallAdapterState } from '@azure/communication-react';

export function useAcsCallState(
  adapter?: CallAdapter
): CallAdapterState | undefined {
  const [callState, setCallState] = useState<CallAdapterState | undefined>();

  useEffect(() => {
    if (!adapter) {
      return;
    }

    adapter.onStateChange(setCallState);
    return () => adapter.offStateChange(setCallState);
  }, [adapter]);

  return callState;
}
