import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControlLabel,
  Grid2,
} from '@mui/material';
import { LoggedDialog } from 'hive-analytics-react';
import { DialogTitleWithClose } from 'hive-mui-utils';
import _fp from 'lodash/fp';

import { Room } from '../../types';
import { useUsers } from '../../hooks';
import { AcsService } from '../../services';
import { CheckedUser, processCheckedUsers } from './utils';

export interface ParticipantsDialogProps {
  room: Room;
  onClose: () => void;
}

export function ParticipantsDialog({
  room,
  onClose,
  ...props
}: ParticipantsDialogProps & DialogProps): JSX.Element {
  const { t } = useTranslation();
  const rawUsers = useUsers();

  const [users, setUsers] = useState<CheckedUser[]>(() =>
    processCheckedUsers(room, rawUsers)
  );
  useEffect(
    () => setUsers(processCheckedUsers(room, rawUsers)),
    [rawUsers, room]
  );

  const onUserChecked = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const acsUserId = event.currentTarget.value;
      if (checked) {
        AcsService.addRoomParticipant(room.id, acsUserId).catch(console.error);
      } else {
        AcsService.removeRoomParticipant(room.id, acsUserId).catch(
          console.error
        );
      }

      setUsers((previous) => {
        const newUsers = _fp.clone(previous);

        const user = _fp.find(
          (u: CheckedUser) => u.properties.acsUserId === acsUserId,
          newUsers
        );
        if (user) {
          user.checked = checked;
        }

        return newUsers;
      });
    },
    [room.id]
  );

  return (
    <LoggedDialog
      dialogName="room-participants-dialog"
      onClose={onClose}
      maxWidth="lg"
      {...props}
    >
      <DialogTitleWithClose onClose={onClose}>
        {t('rooms.participants.dialog.title')}
      </DialogTitleWithClose>

      <DialogContent dividers>
        <Grid2 container>
          {users.map((u: CheckedUser, i: number) => (
            <Grid2
              key={i}
              size={{
                xs: 12,
                sm: users.length >= 2 ? 6 : undefined,
                md: users.length >= 3 ? 4 : undefined,
                lg: users.length >= 4 ? 3 : undefined,
              }}
            >
              <FormControlLabel
                label={u.properties.name}
                control={
                  <Checkbox
                    value={u.properties.acsUserId}
                    checked={u.checked}
                    onChange={onUserChecked}
                  />
                }
              />
            </Grid2>
          ))}
        </Grid2>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          {t('common.ok')}
        </Button>
      </DialogActions>
    </LoggedDialog>
  );
}
