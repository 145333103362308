import { useCallback, useContext } from 'react';
import { Stack, styled } from '@mui/material';

import { AppContext } from '../../contexts';
import { RoomContainer } from './RoomContainer';
import { UserContainer } from './UserContainer';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { AcsService } from '../../services';

const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  width: '100%',
  maxWidth: `${theme.breakpoints.values.md}${theme.breakpoints.unit || 'px'}`,
  alignSelf: 'center',
  alignItems: 'center',
}));

export function Rooms(): JSX.Element {
  const { hasAdminRole, featureFlags } = useContext(AppContext);

  const onDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;
    const destinationRoomId = over?.data?.current?.destination?.id;
    const sourceRoomId = active?.data?.current?.source?.id;
    const participantId =
      active?.data?.current?.participant?.properties.acsUserId;

    if (!participantId) {
      return;
    }

    if (destinationRoomId === sourceRoomId) {
      return;
    }

    if (!destinationRoomId && sourceRoomId) {
      AcsService.removeRoomParticipant(sourceRoomId, participantId).catch(
        console.error
      );
      return;
    }

    if (destinationRoomId) {
      AcsService.addRoomParticipant(destinationRoomId, participantId).catch(
        console.error
      );
      return;
    }
  }, []);

  return (
    <DndContext onDragEnd={onDragEnd}>
      <Container spacing={2}>
        <RoomContainer />

        {featureFlags.supportUserDragAndDrop && hasAdminRole && (
          <UserContainer />
        )}
      </Container>
    </DndContext>
  );
}
