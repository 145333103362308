import { MouseEvent, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionActions,
  AccordionProps,
  AccordionDetails as BaseAccordionDetails,
  AccordionSummary as BaseAccordionSummary,
  IconButton,
  Link,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { useApiCallWithMessageBar } from 'hive-mui-utils';
import { useConfirm } from 'material-ui-confirm';
import _fp from 'lodash/fp';

import ExpandMore from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/EditOutlined';
import JoinIcon from '@mui/icons-material/VideocamOutlined';
import GroupsIcon from '@mui/icons-material/GroupsOutlined';
import PeopleIcon from '@mui/icons-material/PeopleAltOutlined';

import { Room as IRoom, User } from '../../types';
import { AcsService } from '../../services';
import { Paths } from '../../constants';
import { AppContext } from '../../contexts';
import { useUsers } from '../../hooks';
import { Participants as BaseParticipants } from './Participants';
import { useDroppable } from '@dnd-kit/core';
import { ParticipantsDialog } from './ParticipantsDialog';

const AccordionSummary = styled(BaseAccordionSummary)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const AccordionDetails = styled(BaseAccordionDetails)(({ theme }) => ({
  '&.over': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: `rgba(from ${theme.palette.primary.main} r g b / 66%)`,
  },

  // If empty (non-admin might have no content)
  '&:not(:has(*))': {
    padding: 0,
  },
}));

const Participants = styled(BaseParticipants)(({ theme }) => ({
  '&.over': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: `rgba(from ${theme.palette.primary.main} r g b / 66%)`,
  },
}));

interface RoomProps {
  room: IRoom;
  openRoomDialog: (room?: IRoom) => void;
}

export function Room({
  room,
  openRoomDialog,
  ...props
}: RoomProps & Partial<AccordionProps>): JSX.Element {
  const { t } = useTranslation();
  const confirmFn = useConfirm();
  const { hasAdminRole, featureFlags } = useContext(AppContext);
  const users = useUsers();
  const callUrl = useMemo(
    () => `${window.location.origin}${Paths.Call}/${room.id}`,
    [room.id]
  );

  const participants = useMemo(
    () =>
      _fp.flow([
        _fp.map((p: string) =>
          _fp.find((u: User) => u.properties.acsUserId === p, users)
        ),
        _fp.compact,
      ])(room.properties.participants),
    [room.properties.participants, users]
  );

  const { progress: deleting, callback: onDelete } = useApiCallWithMessageBar({
    canExecute: () => !!hasAdminRole,
    execute: () => AcsService.deleteRoom(room.id),
    confirmation: {
      enabled: true,
      title: t('rooms.actions.delete.title'),
      description: t('rooms.actions.delete.message', {
        id: room.id,
      }),
      confirmFn,
    },
    failure: {
      message: t('common.error'),
    },
  });

  const onJoin = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      window.open(callUrl, '_blank');
    },
    [callUrl]
  );

  const { isOver, setNodeRef } = useDroppable({
    disabled: !featureFlags.supportUserDragAndDrop,
    id: room.id,
    data: {
      destination: room,
    },
  });

  const [participantsDialogOpen, setParticipantsDialogOpen] = useState(false);
  const openParticipantsDialog = useCallback(
    () => setParticipantsDialogOpen(true),
    []
  );
  const closeParticipantsDialog = useCallback(
    () => setParticipantsDialogOpen(false),
    []
  );

  return (
    <>
      <ParticipantsDialog
        open={participantsDialogOpen}
        onClose={closeParticipantsDialog}
        room={room}
      />

      <Accordion {...props}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <GroupsIcon fontSize="large" />

              <Typography variant="h5">
                {room.properties.name || room.properties.acsRoomId}
              </Typography>
            </Stack>

            <Link
              href={callUrl}
              target="_blank"
              rel="noopener"
              underline="hover"
              variant="body2"
              onClick={(e) => e.stopPropagation()}
            >
              {callUrl}
            </Link>
          </Stack>
        </AccordionSummary>

        <AccordionDetails className={isOver ? 'over' : undefined}>
          {hasAdminRole && (
            <>
              <Typography variant="h6">{t('room.participants')}</Typography>

              <div ref={setNodeRef}>
                <Participants
                  ref={setNodeRef}
                  room={room}
                  participants={participants}
                  emptyLabelId="room.no_assigned"
                />
              </div>
            </>
          )}
        </AccordionDetails>

        <AccordionActions>
          {hasAdminRole && (
            <IconButton color="error" disabled={deleting} onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          )}

          {hasAdminRole && (
            <IconButton
              disabled={deleting}
              onClick={() => openRoomDialog(room)}
            >
              <EditIcon />
            </IconButton>
          )}

          {hasAdminRole && (
            <IconButton disabled={deleting} onClick={openParticipantsDialog}>
              <PeopleIcon />
            </IconButton>
          )}

          <IconButton onClick={onJoin}>
            <JoinIcon />
          </IconButton>
        </AccordionActions>
      </Accordion>
    </>
  );
}
