import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import _fp from 'lodash/fp';

import { Participant, User } from '../../types';
import { AcsCallContext, AppContext } from '../../contexts';
import { getWaitingParticipants } from './utils';
import { useDebugValue } from 'hive-react-utils';
import {
  useAcceptParticipantCallback,
  useDenyParticipantCallback,
} from '../../hooks';

export interface WaitingParticipantProps {
  participant: User;
}

export function WaitingParticipant({
  participant,
}: WaitingParticipantProps): JSX.Element | null {
  const { t } = useTranslation();
  const { room } = useContext(AcsCallContext);

  const onAccept = useAcceptParticipantCallback(room, participant);
  const onReject = useDenyParticipantCallback(room, participant);

  if (!room || !participant) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center">
      <Typography flexGrow={1}>{participant.properties.name}</Typography>
      <Button onClick={onAccept}>{t('room.waiting.admit')}</Button>

      <Button onClick={onReject}>{t('room.waiting.deny')}</Button>
    </Stack>
  );
}

export function WaitingParticipants(): JSX.Element | null {
  const { t } = useTranslation();
  const { hasAdminRole, featureFlags } = useContext(AppContext);
  const { room, participants: allParticipants } = useContext(AcsCallContext);

  const participants = useMemo<Participant[]>(
    () => getWaitingParticipants(room, allParticipants || []),
    [allParticipants, room]
  );

  useDebugValue(
    room?.properties.waitingParticipants,
    'room.waitingParticipants'
  );
  useDebugValue(participants, 'participants');
  useDebugValue(allParticipants, 'allParticipants');

  if (!hasAdminRole || !featureFlags.supportAttendeeLobby) {
    return null;
  }

  return (
    <Stack spacing={1}>
      <Typography variant="h6">{t('room.waiting.title')}</Typography>

      <Stack spacing={1} paddingLeft={2}>
        {_fp.isEmpty(participants) && (
          <Typography>{t('room.waiting.empty')}</Typography>
        )}

        {participants.map((participant: Participant) => (
          <WaitingParticipant key={participant.id} participant={participant} />
        ))}
      </Stack>
    </Stack>
  );
}
