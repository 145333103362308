import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initializeIcons } from '@fluentui/react';
import { useTitleEffect } from 'hive-react-utils';

import { Loading } from '../Loading';
import { useRoomForCall } from '../../hooks';
import { AppContext } from '../../contexts';
import { Lobby } from './Lobby';
import { Meeting } from './Meeting';
import { Stack, Typography } from '@mui/material';
import { ConfirmProvider } from 'material-ui-confirm';

initializeIcons(undefined, { disableWarnings: true });

enum CallPhase {
  InLobby,
  InCall,
}

export function Call(): JSX.Element {
  const { t } = useTranslation();
  const { hasAdminRole, featureFlags, user } = useContext(AppContext);
  const room = useRoomForCall();

  const [phase, setPhase] = useState<CallPhase>();
  useEffect(() => {
    if (!featureFlags.supportAttendeeLobby) {
      return setPhase(CallPhase.InCall);
    }

    if (hasAdminRole === undefined || !room || !user) {
      return;
    }

    if (hasAdminRole) {
      return setPhase(CallPhase.InCall);
    }

    if (room.properties.acceptedParticipants.includes(user.acsUserId)) {
      return setPhase(CallPhase.InCall);
    }

    return setPhase(CallPhase.InLobby);
  }, [hasAdminRole, user, room, featureFlags]);

  useTitleEffect(
    room ? t('call.title', { name: room.properties.name }) : t('app.title')
  );

  const onAccepted = useCallback(() => setPhase(CallPhase.InCall), []);

  const [rejected, setRejected] = useState(false);
  const onRejected = useCallback(() => setRejected(true), []);

  if (phase === undefined || !room) {
    return <Loading />;
  }

  if (rejected) {
    return (
      <Stack flexGrow={1} alignItems="center" justifyContent="center">
        <Typography>You have no friends!</Typography>
      </Stack>
    );
  }

  if (phase === CallPhase.InLobby) {
    return (
      <Lobby room={room} onAccepted={onAccepted} onRejected={onRejected} />
    );
  }

  return (
    <ConfirmProvider
      defaultOptions={{
        contentProps: { dividers: true },
        confirmationButtonProps: { variant: 'contained' },
        cancellationButtonProps: { variant: 'contained' },
      }}
    >
      <Meeting room={room} />
    </ConfirmProvider>
  );
}
