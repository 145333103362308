export const fr = {
  'app.title': 'ACS App',

  common: {
    ok: 'OK',
    cancel: 'Annuler',
    créer: 'Créer',
    edit: 'Modifier',
    supprimer: 'Supprimer',
    save: 'Sauvegarder',
    yes: 'Oui',
    no: 'Non',
  },

  name: {
    unknown: 'Inconnu(e)',
  },

  signin: {
    oidc: {
      title: "Bienvenue à la démonstration d'Azure Communication Service",
    },
  },

  guest: {
    title:
      "Pour vous connecter en tant qu'invité, veuillez indiquer votre adresse électronique.",
    button: "Continuer en tant qu'invité",
    email: {
      placeholder: 'Saisissez votre adresse électronique',
      invalid: 'Adresse électronique invalide',
    },
  },

  header: {
    title: 'ACS App',
  },

  tabs: {
    rooms: 'Réunions',
    about: 'À propos',
  },

  about: {
    languages: {
      supported: 'Langues supportées',
      current: 'Langue actuelle',
      en: 'English',
      fr: 'Français',
    },

    dark_mode: {
      supported: 'Supporte le mode sombre',
      light: 'Lumineux',
      dark: 'Sombre',
      system: '{{systemMode}} (Système)',
    },

    guests: {
      supported: 'Supporte les utilisateurs invités',
      current: "Type d'utilisateur",
      external: 'Utilisateur',
      bee: 'Invité',
    },

    oidc: {
      provider: 'Fournisseur OpenID Connect',
      entraid: 'Microsoft Entra ID',
      keycloak: 'Keycloak',
    },

    theme: 'Thème',

    version: "Version de l'application",
  },

  rooms: {
    title: 'Réunions',
    not_assigned: 'Aucune réunion ne vous est assignée',

    actions: {
      create: {
        button: 'Créer une réunion...',

        title: {
          new: 'Créer une nouvelle réunion',
          edit: 'Modifier la réunion {{name}}',
        },

        properties: {
          name: 'Nom de la réunion',
        },
      },

      delete: {
        title: 'Effacer la réunion',
        message: 'Effacer la réunion {{id}}',
      },
    },

    participants: {
      button: 'Modifier les participants...',
      dialog: {
        title: 'Modifier les participants',
      },
    },
  },

  room: {
    id: 'ID: {{id}}',
    participants: 'Participants',
    no_assigned: "Aucun participants n'est assigné",

    remote: {
      title: 'Participants connectés',
      empty: "Il n'y a pas de participants connectés",
    },

    unconnected: {
      title: 'Participants non connectés',
      empty: "Il n'y a pas de participants non connectés",
    },

    waiting: {
      title: 'Participants en attente',
      empty: "Il n'y a pas de participants en attente",
      admit: 'Admettre',
      deny: 'Refuser',
    },

    eject: {
      title: 'Éjecter le participant',
      description: 'Éjecter {{name}} de cette réunion ?',
    },

    accept: {
      title: "Accepter l'admission",
      description: "Accepter {{name}} l'admission à cette réunion ?",
    },

    deny: {
      title: "Refuser l'admission",
      description: "Refuser à {{name}} l'accès à cette réunion ?",
    },
  },

  call: {
    title: 'Réunion {{name}}',
    join: 'Joindre une réunion',
    details: 'Réunion {{name}}',
  },

  users: {
    title: 'Utilisateurs',
    no_unassigned: 'Aucun utilisateurs non-assigné',
  },
};
