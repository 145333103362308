import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid2 as Grid,
  Chip as BaseChip,
  styled,
  Grid2Props,
  Typography,
  Stack,
} from '@mui/material';
import { DragOverlay, useDraggable } from '@dnd-kit/core';
import _ from 'lodash';

import { Room, User } from '../../types';
import { AcsService } from '../../services';
import { AppContext } from '../../contexts';
import { sortUsersByName } from './utils';

const Chip = styled(BaseChip)(({ theme }) => ({
  width: 'fit-content',
  '&.MuiChip-deletable': {
    flexDirection: 'row-reverse',

    '& .MuiChip-label': {
      paddingLeft: theme.spacing(0.5),
    },

    '& .MuiChip-deleteIcon': {
      marginLeft: theme.spacing(0.75),
      marginRight: 0,
    },
  },
}));

const ParticipantsGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
}));

interface ParticipantProps {
  room?: Room;
  participant: User;
}

function Participant({ room, participant }: ParticipantProps): JSX.Element {
  const { hasAdminRole, featureFlags } = useContext(AppContext);
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: participant.properties.acsUserId,
    disabled: !!room || !hasAdminRole || !featureFlags.supportUserDragAndDrop,
    data: {
      source: room,
      participant,
    },
  });

  const onDelete = useCallback(
    (event: Event) => {
      event.stopPropagation();
      if (!hasAdminRole || !room) {
        return;
      }
      AcsService.removeRoomParticipant(
        room.id,
        participant.properties.acsUserId
      ).catch(console.error);
    },
    [hasAdminRole, participant.properties.acsUserId, room]
  );

  return (
    <>
      <DragOverlay>
        <Chip label={participant.properties.name} />
      </DragOverlay>

      <Stack
        className="draggable"
        ref={setNodeRef}
        {...listeners}
        {...attributes}
      >
        <Chip
          label={participant.properties.name}
          onDelete={
            featureFlags.supportUserDragAndDrop && hasAdminRole && room
              ? onDelete
              : undefined
          }
        />
      </Stack>
    </>
  );
}

export interface ParticipantsProps {
  room?: Room;
  participants?: User[];
  emptyLabelId: string;
}

export function Participants({
  room,
  participants: unorderedParticipants,
  emptyLabelId,
  ...props
}: ParticipantsProps & Grid2Props): JSX.Element {
  const { t } = useTranslation();
  const participants = useMemo(
    () => sortUsersByName(unorderedParticipants),
    [unorderedParticipants]
  );
  return (
    <ParticipantsGrid
      {...props}
      container
      rowSpacing={1}
      columnSpacing={1}
      rowGap={1}
      columnGap={1}
    >
      {_.isEmpty(participants) && <Typography>{t(emptyLabelId)}</Typography>}

      {_.map(participants, (u) => (
        <Grid key={u.id}>
          <Participant room={room} participant={u} />
        </Grid>
      ))}
    </ParticipantsGrid>
  );
}
