import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuItem, useMediaQuery, Theme, Divider } from '@mui/material';
import { HeaderTab } from 'hive-mui-utils';
import { DisconnectMenuItem } from 'hive-oidc-react';

import { useTabs } from '../hooks';
import { AppContext } from '../contexts';

interface TabMenuItemProps {
  tab: HeaderTab;
}

function TabMenuItem({ tab }: TabMenuItemProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClick = useCallback(() => navigate(tab.path), [navigate, tab.path]);

  return <MenuItem onClick={onClick}>{t(tab.labelId)}</MenuItem>;
}

function TabsAsMenuItems(): JSX.Element {
  const displayTabsInMenu = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const tabs = useTabs();

  if (!displayTabsInMenu) {
    return <></>;
  }

  return (
    <>
      {tabs.map((t: HeaderTab) => (
        <TabMenuItem key={t.path} tab={t} />
      ))}

      <Divider />
    </>
  );
}

export function HeaderMenuItems(): JSX.Element {
  const { isConnected } = useContext(AppContext);
  return (
    <>
      {isConnected && <TabsAsMenuItems />}
      {isConnected && <DisconnectMenuItem />}
    </>
  );
}
