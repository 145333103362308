import { useCallback, useContext, useMemo, useState } from 'react';
import { CallAdapter } from '@azure/communication-react';
import { RemoteParticipant } from '@azure/communication-calling';
import _fp from 'lodash/fp';

import { Participant, Room, User } from '../types';
import { AppContext } from '../contexts';
import { useAcsCallAdapterEventHandler } from './useAcsCallAdapterEventHandler';

const acsUserIdFromRemoteParticipant = (rp: RemoteParticipant) =>
  rp.identifier.kind === 'communicationUser' &&
  rp.identifier.communicationUserId;

const userFromAcsUserId = (users: User[], acsUserId: string) =>
  _fp.find((u: User) => u.properties.acsUserId === acsUserId, users);

const remoteParticipantFromAcsUserId = (
  remoteParticipants: RemoteParticipant[],
  acsUserId: string
) =>
  _fp.find(
    (rp: RemoteParticipant) => acsUserIdFromRemoteParticipant(rp) === acsUserId,
    remoteParticipants
  );

export function useAcsCallParticipants(
  adapter?: CallAdapter,
  room?: Room,
  users?: User[]
) {
  const { hasAdminRole } = useContext(AppContext);

  const [remoteParticipants, setRemoteParticipants] = useState<
    RemoteParticipant[]
  >([]);
  const onParticipantJoined = useCallback(
    (
      _adapter: CallAdapter,
      _eventName: string,
      event: { joined: RemoteParticipant[] }
    ) => setRemoteParticipants(_fp.concat(event.joined)),
    []
  );
  useAcsCallAdapterEventHandler(
    hasAdminRole ? adapter : undefined,
    'participantsJoined',
    onParticipantJoined
  );

  const onParticipantLeft = useCallback(
    (
      _adapter: CallAdapter,
      _eventName: string,
      event: { removed: RemoteParticipant[] }
    ) => {
      const acsUserIdsToRemove = _fp.map(
        acsUserIdFromRemoteParticipant,
        event.removed
      );
      return setRemoteParticipants(
        _fp.reject(
          _fp.flow([
            acsUserIdFromRemoteParticipant,
            _fp.includes(_fp.placeholder, acsUserIdsToRemove),
          ])
        )
      );
    },
    []
  );
  useAcsCallAdapterEventHandler(
    hasAdminRole ? adapter : undefined,
    'participantsLeft',
    onParticipantLeft
  );

  return useMemo<Participant[]>(
    () =>
      _fp.flow([
        _fp.map((acsUserId: string) => {
          const user = userFromAcsUserId(users || [], acsUserId);
          const remote = remoteParticipantFromAcsUserId(
            remoteParticipants,
            acsUserId
          );

          return user ? { ...user, remote } : undefined;
        }),
        _fp.compact,
      ])(room?.properties.participants),
    [remoteParticipants, room?.properties.participants, users]
  );
}
