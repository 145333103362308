import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useInstanceById } from 'hive-react-utils';
import { Room } from '../types';
import { AcsService } from '../services';
import { AppContext } from '../contexts';

export function useRoomForCall(roomId?: string): Room | undefined {
  const { hasAdminRole } = useContext(AppContext);
  const params = useParams();
  const actualRoomId = useMemo(
    () => roomId || params.roomId,
    [params.roomId, roomId]
  );

  // Only for admins
  const roomById = useInstanceById<Room>(
    'acs',
    'room',
    hasAdminRole && actualRoomId ? actualRoomId : ''
  );

  const [room, setRoom] = useState<Room | undefined>();

  useEffect(() => {
    if (hasAdminRole) {
      return setRoom(roomById);
    }

    AcsService.getRoomById(roomId || params.roomId || '')
      .then(setRoom)
      .catch(console.error);
  }, [hasAdminRole, params.roomId, roomId, roomById]);

  return room;
}
