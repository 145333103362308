import { lazy, Suspense } from 'react';
import { Loading } from './Loading';

const BaseOidcProviders = lazy(() => import('./LazyOidcProviders'));

export function OidcProviders(): JSX.Element {
  return (
    <Suspense fallback={<Loading />}>
      <BaseOidcProviders />
    </Suspense>
  );
}
