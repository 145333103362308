import { useContext, useEffect } from 'react';
import { useLoggedConnection } from 'hive-analytics-react';
import _ from 'lodash';
import { AppContext } from '../contexts';
import { Room } from '../types';
import {
  ParticipantAcceptedEvent,
  ParticipantRejectedEvent,
} from './RoomEvents';
import { RoomEventReactionCallback } from './RoomEvents/types';

export interface RoomEventCallbacks {
  onParticipantAccepted?: RoomEventReactionCallback;
  onParticipantRejected?: RoomEventReactionCallback;
}

export function useRoomEvents(room?: Room, callbacks?: RoomEventCallbacks) {
  const { hasAdminRole } = useContext(AppContext);
  const connection = useLoggedConnection();

  useEffect(() => {
    if (hasAdminRole === undefined || !connection?.bee || !room || !callbacks) {
      return;
    }

    const callbackIds: string[] = _.compact([
      ParticipantAcceptedEvent.register(
        connection,
        room,
        callbacks.onParticipantAccepted,
        {
          hasAdminRole,
        }
      ),

      ParticipantRejectedEvent.register(
        connection,
        room,
        callbacks.onParticipantRejected,
        {
          hasAdminRole,
        }
      ),
    ]);

    return () => {
      callbackIds.forEach(connection.bee.reactions.removeCallback);
    };
  }, [connection?.bee, hasAdminRole, room, callbacks, connection]);
}
