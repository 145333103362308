import { assertLoggableConnection } from 'hive-analytics-react';
import { Room, RoomProperties } from '../types';

export async function assertUser(): Promise<void> {
  const connection = assertLoggableConnection();
  return connection.bee.api.invoke('acs_user.assert');
}

export async function refreshToken(): Promise<void> {
  const connection = assertLoggableConnection();
  return connection.bee.api.invoke('acs_user.refreshToken');
}

export async function createRoom(roomName: string): Promise<void> {
  const connection = assertLoggableConnection();
  return connection.bee.api.invoke('acs_room.create', roomName);
}

export async function getRoomById(roomId: string): Promise<Room> {
  const connection = assertLoggableConnection();
  return connection.bee.api.invoke('acs_room.getById', roomId);
}

export async function updateRoom(
  roomId: string,
  properties: Partial<RoomProperties>
): Promise<void> {
  const connection = assertLoggableConnection();
  return connection.bee.api.invoke('acs_room.update', roomId, properties);
}

export async function deleteRoom(roomId: string): Promise<void> {
  const connection = assertLoggableConnection();
  return connection.bee.api.invoke('acs_room.delete', roomId);
}

export async function addRoomParticipant(
  roomId: string,
  participantId: string
): Promise<void> {
  const connection = assertLoggableConnection();
  return connection.bee.api.invoke(
    'acs_room.addParticipant',
    roomId,
    participantId
  );
}

export async function removeRoomParticipant(
  roomId: string,
  participantId: string
): Promise<void> {
  const connection = assertLoggableConnection();
  return connection.bee.api.invoke(
    'acs_room.removeParticipant',
    roomId,
    participantId
  );
}

export async function requestAdmission(roomId: string): Promise<void> {
  const connection = assertLoggableConnection();
  return connection.bee.api.invoke('acs_room_events.requestAdmission', roomId);
}

export async function acceptParticipant(
  roomId: string,
  acsUserId: string
): Promise<void> {
  const connection = assertLoggableConnection();
  return connection.bee.api.invoke(
    'acs_room_events.acceptParticipant',
    roomId,
    acsUserId
  );
}

export async function rejectParticipant(
  roomId: string,
  acsUserId: string
): Promise<void> {
  const connection = assertLoggableConnection();
  return connection.bee.api.invoke(
    'acs_room_events.rejectParticipant',
    roomId,
    acsUserId
  );
}

export const AcsService = {
  assertUser,
  refreshToken,

  createRoom,
  updateRoom,
  deleteRoom,
  getRoomById,

  addRoomParticipant,
  removeRoomParticipant,

  requestAdmission,
  acceptParticipant,
  rejectParticipant,
};
