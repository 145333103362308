import {
  AnalyticsService,
  LoggedConnectionService,
} from 'hive-analytics-react';
import { ConfigService, ConnectionService } from 'hive-client-utils';
import { Paths } from '../constants';

const ANALYTICS_OPTIONS = {
  environment: process.env.NODE_ENV,
  eventIds: {
    appStarted: 'App Started',
    signIn: {
      start: 'Sign In',
      end: 'Signed In',
      error: 'Sign In Error',
    },
    click: 'User Click',
    signOut: {
      start: 'Sign Out',
      end: 'Signed Out',
      error: 'Sign Out Error',
    },
    pageChange: {
      start: 'Page Entered',
      end: 'Page Left',
      error: 'Page Error',
    },
    dialog: {
      start: 'Dialog Displayed',
      end: 'Dialog Hidden',
      error: 'Dialog Error',
    },
    action: {
      start: 'Start Action',
      end: 'End Action',
      error: 'Action Error',
    },
  },
};

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const initAnalyticsIfPossible = (appName: string) =>
  fetch('/tokens/analytics')
    .then((r) => r.json())
    .then((token) =>
      AnalyticsService.init({
        ...ANALYTICS_OPTIONS,
        appName,
        credentials: { type: 'token', token },
      })
    )
    .catch(console.error);

export interface LoginServiceOptions {
  appName: string;
  keyToken: string;
  keyTokenExpiration: string;
  keyUsername: string;
}

async function init({
  keyToken,
  keyTokenExpiration,
  keyUsername,
}: LoginServiceOptions) {
  window.Buffer = window.Buffer || (await import('buffer')).Buffer;

  // await initAnalyticsIfPossible(appName);

  const setBeeFn = (token: ConnectionService.Token, username: string) => {
    localStorage.setItem(keyToken, token.access_token);
    localStorage.setItem(keyTokenExpiration, token.access_token_expiry);
    localStorage.setItem(keyUsername, username);
  };

  const clearBeeFn = () => {
    localStorage.removeItem(keyToken);
    localStorage.removeItem(keyTokenExpiration);
    localStorage.removeItem(keyUsername);
  };

  const loadBeeFn = () => {
    const tokenExpiration = localStorage.getItem(keyTokenExpiration);

    // Check if we have a stored token, if so, we don't need the login screen
    const info: ConnectionService.StorageToken = {
      token: localStorage.getItem(keyToken) || undefined,
      tokenExpiration: tokenExpiration ? parseInt(tokenExpiration) : undefined,
      username: localStorage.getItem(keyUsername) || undefined,
    };

    return info;
  };

  ConnectionService.setupCallbacks({
    setBeeFn,
    clearBeeFn,
    loadBeeFn,
  });

  // Init bee from local storage if possible (when config is available)
  ConfigService.config$.subscribe({
    next: (config: any) => {
      const { pathname } = window.location;
      if (pathname !== Paths.SigningIn) {
        LoggedConnectionService.signInFromStorage(config).catch(console.error);
      }
    },
    error: (error: any) => console.error('Could not get configuration', error),
  });
}

export const LoginService = {
  init,
};
