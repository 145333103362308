import { useCallback, useContext, useMemo, useState } from 'react';
import { Drawer as BaseDrawer, IconButton, Stack, styled } from '@mui/material';
import { LanguageButton, ThemeModeButton } from 'hive-mui-utils';
import _ from 'lodash';

import BaseDragHandleIcon from '@mui/icons-material/DragHandleOutlined';

import { AcsCallContext, AppContext } from '../../contexts';
import { RemoteParticipants } from './RemoteParticipants';
import { WaitingParticipants } from './WaitingParticipants';
import { UnconnectedParticipants } from './UnconnectedParticipants';

const Drawer = styled(BaseDrawer)({});

const Controls = styled(Stack)({
  justifyContent: 'end',
});

const DrawerButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 200,

  backdropFilter: 'contrast(40%)',
  borderRadius: theme.shape.borderRadius,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,

  paddingLeft: 0,
  paddingRight: 0,

  marginLeft: theme.spacing(-2),
  transition: `margin-left ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,

  '&:hover': {
    marginLeft: 0,
  },
}));

const DragHandleIcon = styled(BaseDragHandleIcon)({
  transform: 'rotate(90deg)',
});

export function CallDrawer(): JSX.Element {
  const { hasAdminRole } = useContext(AppContext);
  const { callState, room } = useContext(AcsCallContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const openDrawer = useCallback(() => setDrawerOpen(true), []);
  const closeDrawer = useCallback(() => setDrawerOpen(false), []);

  // Only admins will get a non-null value
  const hasWaitingParticipants = useMemo(
    () =>
      hasAdminRole &&
      !_.isEmpty(room?.properties.waitingParticipants) &&
      callState?.page === 'call',
    [callState?.page, hasAdminRole, room?.properties.waitingParticipants]
  );

  const showHandle = useMemo(() => {
    if (!callState?.page) {
      return false;
    }
    switch (callState?.page) {
      case 'call':
      case 'configuration':
      case 'leftCall':
      case 'leaving':
        return true;

      default:
        return false;
    }
  }, [callState?.page]);

  if (!room) {
    return <></>;
  }

  return (
    <>
      <Drawer
        open={drawerOpen || !!hasWaitingParticipants}
        onClose={closeDrawer}
      >
        <Stack spacing={2} padding={2} role="presentation">
          <Controls direction="row">
            <LanguageButton />
            <ThemeModeButton supported={true} />
          </Controls>

          {/* The following renders only for admins */}
          <RemoteParticipants />

          {/* The following renders only for admins */}
          <WaitingParticipants />

          {/* The following renders only for admins */}
          <UnconnectedParticipants />
        </Stack>
      </Drawer>

      {showHandle && (
        <DrawerButton onClick={openDrawer}>
          <DragHandleIcon />
        </DrawerButton>
      )}
    </>
  );
}
