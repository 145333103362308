import { createContext, useMemo } from 'react';

import { ConnectionService } from 'hive-client-utils';
import {
  AppVersion,
  FeatureFlags,
  useAppVersion,
  useConnectionState,
  useFeatureFlags,
  useHasRole,
} from 'hive-react-utils';

import { useMyUser } from '../hooks';
import { UserProperties } from '../types';

export interface AppFeatureFlags extends FeatureFlags {
  supportUserDragAndDrop: boolean;
  supportAttendeeLobby: boolean;
}

const DEFAULT_FEATURE_FLAGS: AppFeatureFlags = {
  supportUserDragAndDrop: false,
  supportAttendeeLobby: false,
};

export interface AppContextProps {
  appVersion?: AppVersion;
  featureFlags: AppFeatureFlags;
  hasAdminRole?: boolean;

  connectionState?: ConnectionService.ConnectionState;
  isConnected?: boolean;

  user?: UserProperties;
}

export const AppContext = createContext<AppContextProps>({
  featureFlags: DEFAULT_FEATURE_FLAGS,
});

export function AppContextProvider({
  children,
}: {
  children: null | JSX.Element;
}) {
  // IMPORTANT: just keep data that does NOT change often and that can be used often.
  // IMPORTANT2: this context MUST NOT use the Mui nor Copilot context!

  const hasAdminRole = useHasRole('admin');
  const featureFlags = useFeatureFlags(DEFAULT_FEATURE_FLAGS);

  const connectionState = useConnectionState();
  const isConnected =
    connectionState === ConnectionService.ConnectionState.CONNECTED ||
    connectionState === ConnectionService.ConnectionState.CONNECTED_FROM_TOKEN;

  const user = useMyUser();

  const appVersion = useAppVersion();

  const value = useMemo(
    () => ({
      appVersion,
      featureFlags,
      hasAdminRole,

      connectionState,
      isConnected,

      user: user?.properties,
    }),
    [
      appVersion,
      connectionState,
      featureFlags,
      hasAdminRole,
      isConnected,
      user?.properties,
    ]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
