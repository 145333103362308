import { ConnectionService } from 'hive-client-utils';
import { Room } from '../../types';
import { buildReactionHandler } from './utils';
import { RoomEventReactionCallback, RoomEventRegisterFunction } from './types';

const register: RoomEventRegisterFunction = (
  connection: ConnectionService.Connection,
  room: Room,
  callback?: RoomEventReactionCallback
) => {
  if (!callback) {
    return;
  }

  return connection.bee.reactions.setCallback(
    'invocation:participantAccepted',
    buildReactionHandler(room, callback)
  );
};

export const ParticipantAcceptedEvent = { register };
