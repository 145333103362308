import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import { LoginService } from './services';
import { AppContextProvider } from './contexts';
import reportWebVitals from './reportWebVitals';

// The following just need to be imported to start things
import './services/LocalizationService';
import './index.css';

const APP_NAME = 'acs-app';
const KEY_TOKEN = 'acs-app-token';
const KEY_TOKEN_EXPIRATION = 'acs-app-token-expiration';
const KEY_USERNAME = 'acs-app-username';

LoginService.init({
  appName: APP_NAME,
  keyToken: KEY_TOKEN,
  keyTokenExpiration: KEY_TOKEN_EXPIRATION,
  keyUsername: KEY_USERNAME,
}).then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <BrowserRouter basename={process.env.PUBLIC_URL || '/'}>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </BrowserRouter>
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
