import { CallAdapter } from '@azure/communication-react';
import { useEffect } from 'react';

export type AcsCallEventName =
  | 'participantsJoined'
  | 'participantsLeft'
  | 'isMutedChanged'
  | 'callIdChanged'
  | 'isLocalScreenSharingActiveChanged'
  | 'displayNameChanged'
  | 'isSpeakingChanged'
  | 'callEnded'
  | 'diagnosticChanged'
  | 'selectedMicrophoneChanged'
  | 'selectedSpeakerChanged'
  | 'error'
  | 'captionsReceived'
  | 'isCaptionsActiveChanged'
  | 'isCaptionLanguageChanged'
  | 'isSpokenLanguageChanged'
  | 'transferAccepted'
  | 'capabilitiesChanged'
  | 'roleChanged'
  | 'spotlightChanged';

export type AcsCallAdapterEventHandler = (
  adapter: CallAdapter,
  eventName: AcsCallEventName,
  event: any
) => void;

export function useAcsCallAdapterEventHandler(
  adapter: CallAdapter | undefined,
  eventName: AcsCallEventName,
  callback: AcsCallAdapterEventHandler
) {
  useEffect(() => {
    if (!adapter) {
      return;
    }

    const listener = (event: any) => callback(adapter, eventName, event);
    adapter.on(eventName as any, listener);
    return () => {
      adapter.off(eventName as any, listener);
    };
  }, [adapter, callback, eventName]);
}
