import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  styled,
  Typography,
  Button,
  Card as BaseCard,
  CardContent,
} from '@mui/material';
import _ from 'lodash';

import { AppContext } from '../../contexts';
import { useRooms } from '../../hooks';
import { Room as IRoom } from '../../types';
import { RoomDialog } from './RoomDialog';
import { Room } from './Room';

const Card = styled(BaseCard)(() => ({
  width: '100%',
}));

export function RoomContainer(): JSX.Element {
  const { t } = useTranslation();
  const { hasAdminRole } = useContext(AppContext);
  const rawRooms = useRooms();
  const rooms = useMemo(
    () => _.sortBy(rawRooms, 'properties.name'),
    [rawRooms]
  );

  const [dialogRoom, setDialogRoom] = useState<IRoom | undefined>();
  const [roomDialogOpen, setRoomDialogOpen] = useState(false);

  const openRoomDialog = useCallback((room?: IRoom) => {
    setDialogRoom(room);
    setRoomDialogOpen(true);
  }, []);

  const closeRoomDialog = useCallback(() => {
    setDialogRoom(undefined);
    setRoomDialogOpen(false);
  }, []);

  return (
    <Card>
      <CardContent>
        <RoomDialog
          mode={dialogRoom ? 'edit' : 'new'}
          open={roomDialogOpen}
          onClose={closeRoomDialog}
          room={dialogRoom}
        />

        <Stack spacing={1}>
          <Stack direction="row" alignItems="center">
            <Typography variant="h4" flexGrow={1}>
              {t('rooms.title')}
            </Typography>

            {hasAdminRole && (
              <Button variant="contained" onClick={() => openRoomDialog()}>
                {t('rooms.actions.create.button')}
              </Button>
            )}
          </Stack>

          {_.isEmpty(rooms) && (
            <Typography variant="h6">{t('rooms.not_assigned')}</Typography>
          )}

          <Stack>
            {_.map(rooms, (r, index) => (
              <Room
                key={index}
                room={r}
                openRoomDialog={openRoomDialog}
                elevation={0}
              />
            ))}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
