import { useEffect, useMemo, useRef } from 'react';
import { useDecodedToken, useInstancesByQuery } from 'hive-react-utils';
import { useLoggedConnection } from 'hive-analytics-react';
import _fp from 'lodash/fp';
import moment from 'moment';

import { User } from '../types/user';
import { AcsService } from '../services';

const needsTokenRefresh = (user?: User) => {
  if (!user?.properties.acsToken || !user?.properties.acsExpiresOn) {
    return true;
  }

  const max = moment().subtract(5, 'minutes');
  const expiresOn = moment(user.properties.acsExpiresOn);
  return expiresOn.isBefore(max);
};

const refreshTokenIfNeeded = (user?: User) => {
  if (!needsTokenRefresh(user)) {
    return Promise.resolve();
  }

  return AcsService.refreshToken();
};

export function useMyUser(): User | undefined {
  const token = useDecodedToken();
  const connection = useLoggedConnection();
  const rawUsers = useInstancesByQuery<User>(
    'acs',
    'userByUserId',
    'user',
    {},
    token?.userId || ''
  );
  const user = useMemo<User | undefined>(() => _fp.first(rawUsers), [rawUsers]);

  // Assert the user if they do not exist
  const asserting = useRef(false);
  useEffect(() => {
    if (!connection?.bee || user || rawUsers === undefined) {
      return;
    }

    if (!asserting.current) {
      asserting.current = true;
      AcsService.assertUser()
        .catch(console.error)
        .finally(() => {
          asserting.current = false;
        });
    }
  }, [connection?.bee, rawUsers, user]);

  // Refresh the ACS token if needed
  const refreshing = useRef(false);
  useEffect(() => {
    if (!connection?.bee || !user || rawUsers === undefined) {
      return;
    }

    const refresh = () => {
      if (!refreshing.current) {
        refreshing.current = true;
        refreshTokenIfNeeded(user)
          .catch(console.error)
          .finally(() => {
            refreshing.current = false;
          });
      }
    };

    refresh();
    const interval = setInterval(refresh, 60000);
    return () => clearInterval(interval);
  }, [connection?.bee, rawUsers, user]);

  return user;
}
