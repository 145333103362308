import { useMemo } from 'react';
import {
  CallCompositeStrings,
  CallWithChatCompositeStrings,
  ChatCompositeStrings,
  ComponentLocale,
  COMPOSITE_LOCALE_EN_US,
  COMPOSITE_LOCALE_FR_FR,
  CompositeLocale,
} from '@azure/communication-react';
import { useCurrentLanguage } from 'hive-react-utils';
import _ from 'lodash';

export interface PartialCompositeStrings {}

export interface PartialCompositeLocale {
  strings?: {
    call?: Partial<CallCompositeStrings>;
    chat?: Partial<ChatCompositeStrings>;
    callWithChat?: Partial<CallWithChatCompositeStrings>;
  };
  component?: Partial<ComponentLocale>;
}

export function useAcsCallLocale(
  overrides?: PartialCompositeLocale
): CompositeLocale {
  const language = useCurrentLanguage();

  return useMemo<CompositeLocale>(() => {
    const base: CompositeLocale =
      language === 'fr' ? COMPOSITE_LOCALE_FR_FR : COMPOSITE_LOCALE_EN_US;

    return _.merge(base, overrides || {});
  }, [language, overrides]);
}
