import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { PartialTheme } from '@fluentui/react';
import { darkTheme, lightTheme } from '@azure/communication-react';

export function useAcsTheme(): PartialTheme {
  const theme = useTheme();

  return useMemo(() => {
    const baseTheme = theme.palette.mode === 'dark' ? darkTheme : lightTheme;
    return {
      ...baseTheme,
      palette: {
        ...baseTheme.palette,
        themePrimary: theme.palette.primary.main,
      },
      semanticColors: {
        ...baseTheme.semanticColors,
        bodyBackground: theme.palette.background.paper,
        primaryButtonText: theme.palette.primary.contrastText,
      },
    };
  }, [theme]);
}
