import _fp from 'lodash/fp';
import { Participant, Room } from '../../types';

export const isParticipantConnected = (p: Participant) => !!p.remote;

export const isParticipantWaiting = (room?: Room) => (p: Participant) =>
  _fp.includes(p.properties.acsUserId, room?.properties.waitingParticipants);

export const isParticipantMyself = (myAcsUserId?: string) => (p: Participant) =>
  p.properties.acsUserId === myAcsUserId;

export const findParticipantByAcsUserId =
  (participants: Participant[]) => (acsUserId: string) =>
    _fp.find(
      (p: Participant) => p.properties.acsUserId === acsUserId,
      participants
    );

export const getWaitingParticipants = (
  room: Room | undefined,
  participants: Participant[]
) =>
  _fp.flow([
    // Multi-line
    _fp.get('properties.waitingParticipants'),
    _fp.map(findParticipantByAcsUserId(participants)),
    _fp.compact,
  ])(room);
