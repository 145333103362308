import { Suspense, useContext } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Content, MuiContextProvider } from 'hive-mui-utils';
import { useDebugValue, useTitleEffect } from 'hive-react-utils';
import {
  OidcComplete,
  OidcContextProvider,
  OidcPopupMessage,
  usePopupAuthentication,
} from 'hive-oidc-react';

import { LocationTracker } from 'hive-analytics-react';

import { OIDC_CONTEXT_PROPS, Paths } from './constants';
import {
  About,
  HeaderMenuControls,
  HeaderMenuItems,
  HeaderTabs,
  Loading,
  Call,
  Rooms,
  OidcProviders,
  SignInWithOidc,
} from './components';
import { AppContext } from './contexts';
import { appTheme } from './theme';

function NavigateToCall(): JSX.Element {
  const params = useParams();
  return <Navigate to={`${Paths.Call}/${params.roomId}`} replace />;
}

function Routing(): JSX.Element {
  return (
    <>
      {/* Logs analytics events about the route of the user goes to. */}
      <LocationTracker />

      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path={Paths.Rooms} element={<Content Component={Rooms} />} />

          {/* For backward compatibility */}
          <Route path={`/meeting/:roomId`} element={<NavigateToCall />} />
          <Route path={`/room/:roomId`} element={<NavigateToCall />} />

          <Route
            path={`${Paths.Call}/:roomId`}
            element={<Content Component={Call} noHeaderAndFooter={true} />}
          />

          <Route path={Paths.About} element={<Content Component={About} />} />

          <Route path={Paths.SigningIn} element={<SignInWithOidc />} />

          <Route
            path={Paths.SignedIn}
            element={<OidcComplete message={OidcPopupMessage.LoggedIn} />}
          />

          <Route
            path={Paths.SignedOut}
            element={<OidcComplete message={OidcPopupMessage.LoggedOut} />}
          />

          <Route
            path={Paths.Root}
            element={<Navigate to={Paths.Rooms}></Navigate>}
          />

          <Route path="*" element={<Navigate to={Paths.Root}></Navigate>} />
        </Routes>
      </Suspense>
    </>
  );
}
export function App() {
  useTitleEffect('app.title');

  const { appVersion } = useContext(AppContext);
  useDebugValue(appVersion?.version, 'Application Version', '');

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const openPopupAuthentication = usePopupAuthentication();

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />

      <MuiContextProvider
        DisconnectedComponent={OidcProviders}
        TabsComponent={HeaderTabs}
        HeaderMenuItems={HeaderMenuItems}
        HeaderMenuControls={<HeaderMenuControls />}
      >
        <OidcContextProvider
          {...OIDC_CONTEXT_PROPS}
          Controls={HeaderMenuControls}
          // Support popup sign in/out
          signInOptions={{
            open: openPopupAuthentication,
          }}
        >
          <Routing />
        </OidcContextProvider>
      </MuiContextProvider>
    </ThemeProvider>
  );
}
