import { RoomCallLocator } from '@azure/communication-calling';
import { useMemo } from 'react';
import { Room } from '../types';

export function useAcsRoomLocator(room?: Room): RoomCallLocator | undefined {
  return useMemo(() => {
    if (room?.properties.acsRoomId) {
      return { roomId: room.properties.acsRoomId };
    }
  }, [room?.properties.acsRoomId]);
}
