import { useContext, useMemo } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import _fp from 'lodash/fp';

import PersonRemoveIcon from '@mui/icons-material/PersonRemoveOutlined';

import { Participant, User } from '../../types';

import { AcsCallContext, AppContext } from '../../contexts';
import { useTranslation } from 'react-i18next';
import {
  isParticipantConnected,
  isParticipantMyself,
  isParticipantWaiting,
} from './utils';
import { useEjectParticipantCallback } from '../../hooks';

export interface UnconnectedParticipantProps {
  participant: User;
}

export function UnconnectedParticipant({
  participant,
}: UnconnectedParticipantProps): JSX.Element | null {
  const { room } = useContext(AcsCallContext);

  const onEject = useEjectParticipantCallback(room, participant);

  if (!room || !participant) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center">
      <Typography flexGrow={1}>{participant.properties.name}</Typography>

      <IconButton onClick={onEject}>
        <PersonRemoveIcon />
      </IconButton>
    </Stack>
  );
}

export function UnconnectedParticipants(): JSX.Element | null {
  const { t } = useTranslation();
  const { hasAdminRole, user: myUser } = useContext(AppContext);
  const { room, participants: allParticipants } = useContext(AcsCallContext);

  const participants = useMemo<Participant[]>(
    () =>
      _fp.flow([
        _fp.reject(isParticipantConnected),
        _fp.reject(isParticipantWaiting(room)),
        _fp.reject(isParticipantMyself(myUser?.acsUserId)),
      ])(allParticipants),
    [allParticipants, myUser?.acsUserId, room]
  );

  if (!hasAdminRole || !room) {
    return null;
  }

  return (
    <Stack spacing={1}>
      <Typography variant="h6">{t('room.unconnected.title')}</Typography>

      <Stack spacing={1} paddingLeft={2}>
        {_fp.isEmpty(participants) && (
          <Typography>{t('room.unconnected.empty')}</Typography>
        )}

        {participants.map((participant: Participant) => (
          <UnconnectedParticipant
            key={participant.id}
            participant={participant}
          />
        ))}
      </Stack>
    </Stack>
  );
}
