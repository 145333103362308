import _fp from 'lodash/fp';
import { Room, User } from '../../types';

export const tap = (p: string | number) =>
  _fp.tap((v) => console.log(`AAAAA ${p}`, v));

export const sortUsersByName = _fp.sortBy(
  _fp.flow([
    // Multiline
    _fp.get('properties.name'),
    _fp.toLower,
    _fp.deburr,
  ])
);

export type CheckedUser = User & {
  checked: boolean;
};

export const processCheckedUsers = (
  room: Room,
  rawUsers: User[] | undefined
): CheckedUser[] =>
  _fp.flow([
    sortUsersByName,
    _fp.map(
      (u: User) =>
        ({
          ...u,
          checked: _fp.includes(
            u.properties.acsUserId,
            room.properties.participants
          ),
        }) as CheckedUser
    ),
  ])(rawUsers);
