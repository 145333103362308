import { Reaction } from 'hive-client-utils';
import _fp from 'lodash/fp';
import { Room } from '../../types';
import {
  RoomEventReactionCallback,
  RoomEventReactionDetails,
  RoomEventReactionHandler,
} from './types';

export function buildReactionHandler(
  room: Room,
  callback: RoomEventReactionCallback
): RoomEventReactionHandler {
  return (reaction: Reaction) => {
    const details: RoomEventReactionDetails = _fp.flow([
      _fp.get('details'),
      _fp.castArray,
      _fp.get(0),
    ])(reaction);

    if (details.roomId !== room.id) {
      return;
    }

    if (!room.properties.participants.includes(details.acsUserId)) {
      return;
    }

    callback(details);
  };
}
