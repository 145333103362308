import { useMemo } from 'react';
import { CallCompositeOptions } from '@azure/communication-react';

export function useAcsCallOptions(): CallCompositeOptions {
  return useMemo<CallCompositeOptions>(
    () => ({
      galleryOptions: {
        layout: 'focusedContent',
      },
      branding: {
        logo: {
          url: '/logo512.png',
          alt: 'logo',
          shape: 'circle',
        },
      },
    }),
    []
  );
}
