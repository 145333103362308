import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'material-ui-confirm';
import { Room, User } from '../types';
import { AcsService } from '../services';

export function useDenyParticipantCallback(
  room: Room | undefined,
  participant: User
) {
  const { t } = useTranslation();
  const confirm = useConfirm();

  return useCallback(() => {
    if (!room) {
      return;
    }

    confirm({
      title: t('room.deny.title'),
      description: t('room.deny.description', {
        name: participant.properties.name,
      }),
      confirmationButtonProps: { color: 'error' },
      confirmationText: t('common.yes'),
      cancellationText: t('common.no'),
    })
      .then(() =>
        AcsService.rejectParticipant(
          room!.id,
          participant.properties.acsUserId
        ).catch(console.error)
      )
      .catch(() => {
        // Just ignore cancellation
      });
  }, [
    confirm,
    participant.properties.acsUserId,
    participant.properties.name,
    room,
    t,
  ]);
}
