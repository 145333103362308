import { useState } from 'react';
import { VideoBackgroundImage } from '@azure/communication-react';

import backgroundImage1 from '../assets/oidc-background.png';
import backgroundImage2 from '../assets/oidc-background-dark.png';

export function useAcsVideoBackgroundImages(): VideoBackgroundImage[] {
  const [images] = useState(() => [
    {
      key: 'background1',
      url: `${window.location.origin}${backgroundImage1}`,
    },
    {
      key: 'background2',
      url: `${window.location.origin}${backgroundImage2}`,
    },
  ]);

  return images;
}
