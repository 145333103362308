import { useContext, useEffect, useState } from 'react';
import { useInstancesByClassName } from 'hive-react-utils';

import { User } from '../types/user';
import { AppContext } from '../contexts';

export function useUsers(): User[] | undefined {
  const { hasAdminRole } = useContext(AppContext);
  const [users, setUsers] = useState<User[] | undefined>();

  const allUsers = useInstancesByClassName<User>('acs', 'user', {
    debounce: true,
    debounceWait: 5000,
    debounceMaxWait: 30000,
  });

  useEffect(() => {
    if (!allUsers) {
      return setUsers(undefined);
    }

    return setUsers(allUsers);
  }, [allUsers, hasAdminRole]);

  return users;
}
