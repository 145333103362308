import { useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { HeaderTab } from 'hive-mui-utils';

import { Paths, TAB_ABOUT, TAB_ROOMS } from '../constants';
import { AppContext } from '../contexts';

export function useTabs(): HeaderTab[] {
  const { isConnected } = useContext(AppContext);
  const location = useLocation();

  return useMemo<HeaderTab[]>(() => {
    const tabs: HeaderTab[] = [];

    if (!location.pathname.startsWith(`${Paths.Call}`) && isConnected) {
      tabs.push(TAB_ROOMS);
      tabs.push(TAB_ABOUT);
    }

    return tabs;
  }, [isConnected, location.pathname]);
}
