import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Stack, Typography } from '@mui/material';
import _fp from 'lodash/fp';

import MicOnIcon from '@mui/icons-material/MicOutlined';
import MicOffIcon from '@mui/icons-material/MicOffOutlined';
import PersonRemoveIcon from '@mui/icons-material/PersonRemoveOutlined';

import { Participant } from '../../types';

import { AcsCallContext, AppContext } from '../../contexts';
import { isParticipantConnected } from './utils';
import { useEjectParticipantCallback } from '../../hooks';

export interface RemoteParticipantProps {
  participant: Participant;
}

export function RemoteParticipant({
  participant,
}: RemoteParticipantProps): JSX.Element | null {
  const { room } = useContext(AcsCallContext);

  // We can only mute, not unmute
  const muteDisabled = !participant.remote || participant.remote.isMuted;
  const onMute = useCallback(() => {
    participant.remote?.mute();
  }, [participant.remote]);

  const onEject = useEjectParticipantCallback(room, participant);

  if (!room || !participant) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center">
      <Typography flexGrow={1}>{participant.properties.name}</Typography>

      <IconButton disabled={muteDisabled} onClick={onMute}>
        {participant.remote?.isMuted ? <MicOffIcon /> : <MicOnIcon />}
      </IconButton>

      <IconButton onClick={onEject}>
        <PersonRemoveIcon />
      </IconButton>
    </Stack>
  );
}

export function RemoteParticipants(): JSX.Element | null {
  const { t } = useTranslation();
  const { hasAdminRole } = useContext(AppContext);
  const { room, participants: allParticipants } = useContext(AcsCallContext);

  const participants = useMemo<Participant[]>(
    () => _fp.filter(isParticipantConnected, allParticipants),
    [allParticipants]
  );

  if (!hasAdminRole || !room) {
    return null;
  }

  return (
    <Stack spacing={1}>
      <Typography variant="h6">{t('room.remote.title')}</Typography>

      <Stack spacing={1} paddingLeft={2}>
        {_fp.isEmpty(participants) && (
          <Typography>{t('room.remote.empty')}</Typography>
        )}

        {participants?.map((participant) => (
          <RemoteParticipant key={participant.id} participant={participant} />
        ))}
      </Stack>
    </Stack>
  );
}
