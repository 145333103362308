import { useContext, useMemo } from 'react';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { AppContext } from '../contexts';

export function useAcsCredentials():
  | AzureCommunicationTokenCredential
  | undefined {
  const { user } = useContext(AppContext);

  return useMemo(() => {
    try {
      if (user?.acsToken) {
        return new AzureCommunicationTokenCredential(user.acsToken);
      }
    } catch (e) {
      console.log(e);
    }

    return undefined;
  }, [user?.acsToken]);
}
