import { CallAdapter, CallAdapterState } from '@azure/communication-react';
import { Participant, Room, User } from '../types';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { useAcsCallParticipants } from '../hooks';
import { AppContext } from './AppContext';

export interface AcsCallContextProps {
  adapter?: CallAdapter;
  room?: Room;
  users?: User[];
  callState?: CallAdapterState;
  participants?: Participant[];
}

export const AcsCallContext = createContext<AcsCallContextProps>({});

export function AcsCallContextProvider({
  adapter,
  room,
  users,
  callState,
  children,
}: PropsWithChildren<AcsCallContextProps>): JSX.Element {
  const { hasAdminRole } = useContext(AppContext);
  const participants = useAcsCallParticipants(
    hasAdminRole ? adapter : undefined,
    room,
    users
  );

  const value = useMemo<AcsCallContextProps>(
    () => ({ adapter, room, users, callState, participants }),
    [adapter, room, users, callState, participants]
  );

  return (
    <AcsCallContext.Provider value={value}>{children}</AcsCallContext.Provider>
  );
}
