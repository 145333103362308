import { useTranslation } from 'react-i18next';
import {
  Stack,
  styled,
  Typography,
  Card as BaseCard,
  CardContent,
} from '@mui/material';

import { useUsers } from '../../hooks';
import { Participants } from './Participants';

const Card = styled(BaseCard)({
  width: '100%',
});

export function UserContainer(): JSX.Element {
  const { t } = useTranslation();
  const users = useUsers();

  return (
    <Card>
      <CardContent>
        <Stack direction="row" spacing={1}>
          <Typography variant="h4" flexGrow={1}>
            {t('users.title')}
          </Typography>
        </Stack>

        <Participants participants={users} emptyLabelId="users.no_unassigned" />
      </CardContent>
    </Card>
  );
}
