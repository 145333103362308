import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  Input,
  InputLabel,
} from '@mui/material';
import { DialogTitleWithClose, useApiCallWithMessageBar } from 'hive-mui-utils';
import { LoggedDialog } from 'hive-analytics-react';

import { Room } from '../../types';
import { AcsService } from '../../services';

export interface RoomDialogProps {
  mode: 'new' | 'edit';
  room?: Room;
  onClose: () => void;
}
export function RoomDialog({
  mode,
  room,
  open,
  onClose,
  ...props
}: RoomDialogProps & DialogProps): JSX.Element {
  const { t } = useTranslation();

  const [name, setName] = useState<string>(room?.properties.name || '');
  const onNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  }, []);

  const { progress: creating, callback: onCreate } = useApiCallWithMessageBar({
    canExecute: () => mode === 'new' && !!name,
    execute: () => AcsService.createRoom(name),
    success: { onClose },
    failure: {
      message: t('common.error'),
    },
  });

  const { progress: editing, callback: onEdit } = useApiCallWithMessageBar({
    canExecute: () => mode === 'edit' && !!room?.id && !!name,
    execute: () =>
      AcsService.updateRoom(room!.id, {
        name,
      }),
    success: { onClose },
    failure: {
      message: t('common.error'),
    },
  });

  useEffect(() => {
    setName(open ? room?.properties.name || '' : '');
  }, [open, room?.properties.name]);

  const submitRef = useRef<HTMLButtonElement>(null);
  const onKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      submitRef.current?.click();
    }
  }, []);

  return (
    <LoggedDialog
      open={open}
      dialogName="room-dialog"
      onClose={onClose}
      disableRestoreFocus
      {...props}
    >
      <DialogTitleWithClose onClose={onClose}>
        {t(`rooms.actions.create.title.${mode}`, {
          name: room?.properties.name,
        })}
      </DialogTitleWithClose>

      <DialogContent dividers>
        <FormControl required={true} variant="filled" fullWidth>
          <InputLabel required={true}>
            {t('rooms.actions.create.properties.name')}
          </InputLabel>
          <Input
            autoFocus
            value={name}
            onChange={onNameChange}
            onKeyDown={onKeyDown}
          />
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          disabled={creating || editing}
          onClick={onClose}
        >
          {t('common.cancel')}
        </Button>

        {mode === 'new' && (
          <Button
            ref={submitRef}
            variant="contained"
            disabled={!name || creating || editing}
            onClick={onCreate}
          >
            {t('common.create')}
          </Button>
        )}

        {mode === 'edit' && (
          <Button
            ref={submitRef}
            variant="contained"
            disabled={!name || creating || editing}
            onClick={onEdit}
          >
            {t('common.save')}
          </Button>
        )}
      </DialogActions>
    </LoggedDialog>
  );
}
