import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import {
  Card as BaseCard,
  CardContent as BaseCardContent,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { Room } from '../../types';
import { AppContext } from '../../contexts';
import { AcsService } from '../../services';
import { RoomEventCallbacks, useRoomEvents } from '../../hooks';
import {
  RoomEventReactionCallback,
  RoomEventReactionDetails,
} from '../../hooks/RoomEvents';

const Container = styled(Stack)({
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
});

const Card = styled(BaseCard)();
const CardContent = styled(BaseCardContent)();

export interface LobbyProps {
  room: Room;

  onRejected?: (reason?: string) => void;
  onAccepted?: () => void;
}

export function Lobby({
  room,
  onAccepted,
  onRejected,
}: LobbyProps): JSX.Element {
  const { user } = useContext(AppContext);

  const messageSent = useRef(false);
  useEffect(() => {
    if (messageSent.current) {
      return;
    }

    messageSent.current = true;
    AcsService.requestAdmission(room.id).catch(console.error);
  }, [room]);

  const onParticipantAccepted = useCallback<RoomEventReactionCallback>(
    (details: RoomEventReactionDetails) => {
      if (user?.acsUserId === details.acsUserId) {
        onAccepted?.();
      }
    },
    [onAccepted, user?.acsUserId]
  );

  const onParticipantRejected = useCallback<RoomEventReactionCallback>(
    (details: RoomEventReactionDetails) => {
      if (user?.acsUserId === details.acsUserId) {
        onRejected?.();
      }
    },
    [onRejected, user?.acsUserId]
  );

  const callbacks = useMemo<RoomEventCallbacks>(
    () => ({
      onParticipantAccepted,
      onParticipantRejected,
    }),
    [onParticipantAccepted, onParticipantRejected]
  );

  useRoomEvents(room, callbacks);

  return (
    <Container>
      <Card>
        <CardContent>
          <Typography>Waiting to be admitted</Typography>
        </CardContent>
      </Card>
    </Container>
  );
}
